import { Brand, BrandCollection } from 'models/entities/brand';
import { CompanyCollection } from 'models/entities/company';
import { Headquarters } from 'models/entities/headquarters';
import { Franchisor } from 'models/entities/franchisor';
import { Franchisee } from 'models/entities/franchisee';
import { Store } from 'models/entities/store';
import { Organization } from 'models/entities/organization';
import { User, UserEditor } from 'models/entities/user';

import { Condition } from './models/condition';
import { ReadGql } from './models/read-gql';

class Model {

  readonly condition: Condition;
  readonly collection?: BrandCollection;
  readonly list?: Brand[];
  readonly companies?: CompanyCollection;

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const gql = await new ReadGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    const collection = new BrandCollection(gql.result.collection);
    const list = this.condition.filter(collection.documents);
    const companies = new CompanyCollection(gql.result.companies);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list, companies });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCompanies(): Promise<this> {
    if (!this.companies) throw new Error('companies is undefined');
    const companies = await this.companies.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, companies });
  }

  build(): Brand {
    return new Brand();
  }

  add(brand: Brand): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(brand));
  }

  replace(brand: Brand): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(brand));
  }

  remove(brand: Brand): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(brand));
  }

  private apply(collection: BrandCollection): this {
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  buildHeadquarters(brand: Brand): Headquarters {
    const brandIdentity = brand.getIdentity();
    return new Headquarters({ brandIdentity });
  }

  applyOrganization(organization: Organization): this {
    switch (true) {
      case organization instanceof Headquarters: return this.applyHeadquarters(organization as Headquarters);
      case organization instanceof Franchisor: return this.applyFranchisor(organization as Franchisor);
      case organization instanceof Franchisee: return this.applyFranchisee(organization as Franchisee);
      case organization instanceof Store: return this.applyStore(organization as Store);
      default: throw new Error('invalid organization');
    }
  }

  applyHeadquarters(headquarters: Headquarters): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.applyHeadquarters(headquarters));
  }

  removeHeadquarters(headquarters: Headquarters): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.removeHeadquarters(headquarters));
  }

  buildStore(organization: Headquarters | Franchisor | Franchisee): Store {
    return new Store({ organizationId: organization.id });
  }

  applyStore(store: Store): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.applyStore(store));
  }

  removeStore(store: Store): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.removeStore(store));
  }

  buildFranchisor(headquarters: Headquarters): Franchisor {
    return new Franchisor({ organizationId: headquarters.id });
  }

  applyFranchisor(franchisor: Franchisor): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.applyFranchisor(franchisor));
  }

  removeFranchisor(franchisor: Franchisor): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.removeFranchisor(franchisor));
  }

  buildFranchisee(franchisor: Franchisor): Franchisee {
    return new Franchisee({ organizationId: franchisor.id });
  }

  applyFranchisee(franchisee: Franchisee): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.applyFranchisee(franchisee));
  }

  removeFranchisee(franchisee: Franchisee): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.removeFranchisee(franchisee));
  }

  buildUser(organization: Organization): User {
    return new User({ organizationId: organization.id });
  }

  buildUserEditor(original: User): UserEditor {
    return new UserEditor({ original });
  }

  incrementUserCount(organization: Organization): this {
    switch (true) {
      case organization instanceof Headquarters: return this.applyHeadquarters(organization.incrementUserCount() as Headquarters);
      case organization instanceof Franchisor: return this.applyFranchisor(organization.incrementUserCount() as Franchisor);
      case organization instanceof Franchisee: return this.applyFranchisee(organization.incrementUserCount() as Franchisee);
      case organization instanceof Store: return this.applyStore(organization.incrementUserCount() as Store);
      default: throw new Error('invalid organization');
    }
  }

}

export { Model };