import { Api } from 'models/services/api';
import { ReadBrandsQuery, ReadCompaniesQuery } from 'models/services/graphql';
import { BrandCollectionData } from 'models/entities/brand';
import { CompanyCollectionData } from 'models/entities/company';

const RequestQuery = `
  query Brands($nextToken: String) {
    ${ReadBrandsQuery}
    ${ReadCompaniesQuery}
  }
`;

type ResponseData = {
  data: {
    readBrands: BrandCollectionData;
    readCompanies: CompanyCollectionData;
  }
}

type Result = {
  collection: BrandCollectionData;
  companies: CompanyCollectionData;
}

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(): Promise<this> {
    const api = await this.api.fetch();
    if (!api.response) throw new Error('invalid response');
    const collection = api.response.data.readBrands;
    const companies = api.response.data.readCompanies;
    const result = { collection, companies };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };