import React, { FC, useRef, MouseEvent } from 'react';

import { useDropdown, BrandsActionsProps } from 'views/services/dropdown';
import { MoreActionsIcon } from 'views/components/icons';
import { Root } from './index.styled';

const ActionsButton: FC<BrandsActionsProps> = props => {

  const dropdown = useDropdown();
  const button = useRef<HTMLButtonElement>(null);

  function onClick(e: MouseEvent<HTMLButtonElement>) {
    const target = e.currentTarget;
    const button = target.getBoundingClientRect();
    const x = button.left + button.width;
    const y = button.top + button.height;
    dropdown.toggle('brands-actions', { target, point: { x, y }, props });
  }

  return (
    <Root>
      <button ref={button} onClick={onClick}><MoreActionsIcon /></button>
    </Root>
  );

};

export { ActionsButton };