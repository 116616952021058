import React, { useState, useEffect, Fragment } from 'react';

import { Brand } from 'models/entities/brand';
import { Headquarters } from 'models/entities/headquarters';
import { Franchisor } from 'models/entities/franchisor';
import { Franchisee } from 'models/entities/franchisee';
import { Store } from 'models/entities/store';
import { User } from 'models/entities/user';
import { Organization } from 'models/entities/organization';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useModal, EditUserTarget, ShowUsersTarget, ModalResult } from 'views/services/modal';
import { BrandsActionName } from 'views/services/dropdown';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { UsersButton } from 'views/components/users-button';
import { ActionsButton } from './views/actions-button';
import { Root, Frame, List, ListHeader, ListItem, Content, Actions, NoItem } from './index.styled';

const Brands = () => {

  const auth = useAuth();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(brand?: Brand) {
    const target = brand ?? model.build();
    const tell = apply;
    modal.open('edit-brand', { target, strict: true, tell });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Brand)) return;
    const brand = result.target as Brand;
    switch (result.state) {
      case 'created': setModel(model.add(brand)); break;
      case 'updated': setModel(model.replace(brand)); break;
      case 'deleted': setModel(model.remove(brand)); break;
    }
  }

  function selectBrandAction(action: BrandsActionName, brand: Brand) {
    switch (action) {
      case 'edit-headquarters': return editHeadquarters(model.buildHeadquarters(brand));
    }
  }

  function editHeadquarters(target: Headquarters) {
    const tell = applyHeadquarters;
    modal.open('edit-headquarters', { target, strict: true, other: { companies: model.companies }, tell });
  }

  function applyHeadquarters(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Headquarters)) return;
    const headquarters = result.target as Headquarters;
    switch (result.state) {
      case 'created': setModel(model.applyHeadquarters(headquarters)); break;
      case 'updated': setModel(model.applyHeadquarters(headquarters)); break;
      case 'deleted': setModel(model.removeHeadquarters(headquarters)); break;
    }
  }

  function selectHeadquartersAction(action: BrandsActionName, headquarters: Headquarters) {
    switch (action) {
      case 'edit-store': return editStore(model.buildStore(headquarters));
      case 'edit-franchisor': return editFranchisor(model.buildFranchisor(headquarters));
      case 'edit-user': return editUser({ user: model.buildUser(headquarters), organization: headquarters });
    }
  }

  function selectFranchisorAction(action: BrandsActionName, franchisor: Franchisor) {
    switch (action) {
      case 'edit-store': return editStore(model.buildStore(franchisor));
      case 'edit-franchisee': return editFranchisee(model.buildFranchisee(franchisor));
      case 'edit-user': return editUser({ user: model.buildUser(franchisor), organization: franchisor });
    }
  }

  function selectFranchiseeAction(action: BrandsActionName, franchisee: Franchisee) {
    switch (action) {
      case 'edit-store': return editStore(model.buildStore(franchisee));
      case 'edit-user': return editUser({ user: model.buildUser(franchisee), organization: franchisee });
    }
  }

  function editStore(target: Store) {
    const tell = applyStore;
    modal.open('edit-store', { target, strict: true, tell });
  }

  function applyStore(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Store)) return;
    const store = result.target as Store;
    switch (result.state) {
      case 'created': setModel(model.applyStore(store)); break;
      case 'updated': setModel(model.applyStore(store)); break;
      case 'deleted': setModel(model.removeStore(store)); break;
    }
  }

  function editFranchisor(target: Franchisor) {
    const tell = applyFranchisor;
    modal.open('edit-franchisor', { target, strict: true, other: { companies: model.companies }, tell });
  }

  function applyFranchisor(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Franchisor)) return;
    const franchisor = result.target as Franchisor;
    switch (result.state) {
      case 'created': setModel(model.applyFranchisor(franchisor)); break;
      case 'updated': setModel(model.applyFranchisor(franchisor)); break;
      case 'deleted': setModel(model.removeFranchisor(franchisor)); break;
    }
  }

  function editFranchisee(target: Franchisee) {
    const tell = applyFranchisee;
    modal.open('edit-franchisee', { target, strict: true, other: { companies: model.companies }, tell });
  }

  function applyFranchisee(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Franchisee)) return;
    const franchisee = result.target as Franchisee;
    switch (result.state) {
      case 'created': setModel(model.applyFranchisee(franchisee)); break;
      case 'updated': setModel(model.applyFranchisee(franchisee)); break;
      case 'deleted': setModel(model.removeFranchisee(franchisee)); break;
    }
  }

  function selectStoreAction(action: BrandsActionName, store: Store) {
    switch (action) {
      case 'edit-user': return editUser({ user: model.buildUser(store), organization: store });
    }
  }

  function editUser({ user, organization }: { user: User, organization: Organization }) {
    const target = new EditUserTarget({ user: model.buildUserEditor(user), organization });
    const tell = applyUser;
    modal.open('edit-user', { target, strict: true, tell });
  }

  function applyUser(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof EditUserTarget)) return;
    const { organization } = result.target;
    switch (result.state) {
      case 'created': setModel(model.incrementUserCount(organization)); break;
    }
  }

  function showUsers(organization: Organization) {
    const target = new ShowUsersTarget({ organization });
    const tell = applyUsers;
    modal.open('show-users', { target, strict: true, tell });
  }

  function applyUsers(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof ShowUsersTarget)) return;
    const { organization } = result.target;
    switch (result.state) {
      case 'updated': setModel(model.applyOrganization(organization)); break;
    }
  }

  function watchAuth() {
    if (!auth.ok) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  function watchCompanies() {
    if (!model.companies) return;
    if (!model.companies.nextToken) return;
    model.readAllCompanies().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok]);
  useEffect(watchCollection, [model.collection]);
  useEffect(watchCompanies, [model.companies]);

  return (
    <Root>
      <Header mode={auth.user.admin ? 'admin' : ''} onCreate={() => edit()} />
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData>No Brand</NoInterfaceData> : model.list.map((brand, i) => (
            <List key={i}>
              <ListHeader>
                <Content onClick={() => edit(brand)}>
                  <div>{brand.name}</div>
                </Content>
                <Actions>
                  <div><ActionsButton target={brand} onSelect={action => selectBrandAction(action, brand)} /></div>
                </Actions>
              </ListHeader>
              {!brand.headquarters && (
                <NoItem>No Headquarters</NoItem>
              )}
              {!!brand.headquarters && (
                <>
                  <ListItem>
                    <Content onClick={() => editHeadquarters(brand.headquarters!)}>
                      <div>{brand.headquarters.name}</div>
                      <div>Headquarters</div>
                    </Content>
                    <Actions>
                      <div><UsersButton userCount={brand.headquarters.userCount} onPush={() => showUsers(brand.headquarters!)} /></div>
                      <div><ActionsButton target={brand.headquarters} onSelect={action => selectHeadquartersAction(action, brand.headquarters!)} /></div>
                    </Actions>
                  </ListItem>
                  {!!brand.headquarters?.stores.documents.length && brand.headquarters.stores.documents.map((store, i) => (
                    <ListItem key={i}>
                      <Content onClick={() => editStore(store)}>
                        <div>{store.name}</div>
                        <div>Store</div>
                      </Content>
                      <Actions>
                        <div><UsersButton userCount={store.userCount} onPush={() => showUsers(store)} /></div>
                        <div><ActionsButton target={store} onSelect={action => selectStoreAction(action, store)} /></div>
                      </Actions>
                    </ListItem>
                  ))}
                  {!!brand.headquarters?.franchisors.documents.length && brand.headquarters.franchisors.documents.map((franchisor, i) => (
                    <Fragment key={i}>
                      <ListItem>
                        <Content onClick={() => editFranchisor(franchisor)}>
                          <div>{franchisor.name}</div>
                          <div>Franchisor</div>
                        </Content>
                        <Actions>
                          <div><UsersButton userCount={franchisor.userCount} onPush={() => showUsers(franchisor)} /></div>
                          <div><ActionsButton target={franchisor} onSelect={action => selectFranchisorAction(action, franchisor)} /></div>
                        </Actions>
                      </ListItem>
                      {!!franchisor.stores.documents.length && franchisor.stores.documents.map((store, j) => (
                        <ListItem key={j}>
                          <Content onClick={() => editStore(store)}>
                            <div>{store.name}</div>
                            <div>Store</div>
                          </Content>
                          <Actions>
                            <div><UsersButton userCount={store.userCount} onPush={() => showUsers(store)} /></div>
                            <div><ActionsButton target={store} onSelect={action => selectStoreAction(action, store)} /></div>
                          </Actions>
                        </ListItem>
                      ))}
                      {!!franchisor.franchisees.documents.length && franchisor.franchisees.documents.map((franchisee, i) => (
                        <Fragment key={i}>
                          <ListItem>
                            <Content onClick={() => editFranchisee(franchisee)}>
                              <div>{franchisee.name}</div>
                              <div>Franchisee</div>
                            </Content>
                            <Actions>
                              <div><UsersButton userCount={franchisee.userCount} onPush={() => showUsers(franchisee)} /></div>
                              <div><ActionsButton target={franchisee} onSelect={action => selectFranchiseeAction(action, franchisee)} /></div>
                            </Actions>
                          </ListItem>
                          {!!franchisee.stores.documents.length && franchisee.stores.documents.map((store, j) => (
                            <ListItem key={j}>
                              <Content onClick={() => editStore(store)}>
                                <div>{store.name}</div>
                                <div>Store</div>
                              </Content>
                              <Actions>
                                <div><UsersButton userCount={store.userCount} onPush={() => showUsers(store)} /></div>
                                <div><ActionsButton target={store} onSelect={action => selectStoreAction(action, store)} /></div>
                              </Actions>
                            </ListItem>
                          ))}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </>
              )}
            </List>
          ))}
        </Frame>
      )}
    </Root>
  );

};

export default Brands;