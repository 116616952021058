import styled from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight } from 'views/services/font-scheme';
import { baseColor, darkBaseColor, textColor, lightTextColor, lineColor, lightPrimaryColor, darkPrimaryColor, vividPrimaryColor, primaryTextColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

export const Root = styled.div`
`;

export const Frame = styled.div`
  width: 100vw;
  height: calc(100vh - ${headerHeight});
  overflow: scroll;
  background-color: ${darkBaseColor};
`;

export const List = styled.div`
`;

export const ListHeader = styled.div`
  z-index: 10;
  position: sticky;
  top: 0;
  height: ${headerHeight};
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1);
  line-height: ${baseLineHeight};
  font-weight: bold;
  background-color: ${darkPrimaryColor};
  color: ${primaryTextColor};
  border-top: 1px solid ${darkPrimaryColor};
  border-bottom: 1px solid ${darkPrimaryColor};
  margin-bottom: -1px;
  &:hover {
    background-color: ${lightPrimaryColor};
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      bottom: -1px;
      border-top: 1px solid ${vividPrimaryColor};
      border-bottom: 1px solid ${vividPrimaryColor};
      pointer-events: none;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListItem = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1);
  line-height: ${baseLineHeight};
  background-color: ${baseColor};
  color: ${textColor};
  height: ${headerHeight};
  border-top: 1px solid ${lineColor};
  border-bottom: 1px solid ${lineColor};
  margin-bottom: -1px;
  &:hover {
    background-color: ${lightPrimaryColor};
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      bottom: -1px;
      border-top: 1px solid ${vividPrimaryColor};
      border-bottom: 1px solid ${vividPrimaryColor};
      pointer-events: none;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  flex: 1 0 auto;
  height: 100%;
  padding-left: 16px;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  & > div {
    &:nth-child(1) {
      min-width: 20%;
      max-width: 30%;
    }
  }
`;

export const Actions = styled.div`
  flex: 0 0 108px;
  height: 100%;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    flex: 1;
    display: flex;
    justify-content: center;
    &:nth-last-child(1) {
      justify-content: end;
    }
    align-items: center;
  }
`;

export const NoItem = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 2);
  background-color: ${baseColor};
  color: ${lightTextColor};
  border-top: 1px solid ${lineColor};
  border-bottom: 1px solid ${lineColor};
  height: calc(${headerHeight} * 3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

